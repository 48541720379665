<template>
  <VCard :class="$style.card" class="m-auto">
    <VCardTitle>
      <VIcon class="mr-2" large v-text="icon"/>
      <span>{{ name }}</span>
    </VCardTitle>
    <VCardSubtitle :class="$style.subtitle">{{ description }}</VCardSubtitle>
    <VCardText>
      <VAlert v-bind="{...registryIconColorControl(status)}" :class="$style.status" v-for="({name, status, date}, index) in (open ? statuses : statuses.slice(0, 2))" :key="index" dark dense>
        <b>{{ status.name }}</b>
        <span v-if="date"> до {{ date }} </span>
        <span v-if="name"> для {{ name }}</span>
      </VAlert>
      <div v-if="statuses.length > 2" class="my-2" @click.stop.prevent="open = !open">
        <span v-if="!open" :class="$style.link">Показать все заявки <VIcon v-text="'mdi-menu-down'"/></span>
        <span v-else :class="$style.link">Скрыть <VIcon v-text="'mdi-menu-up'"/></span>
      </div>
    </VCardText>
    <VCardActions>
      <div style="width: 100%" class="clearfix" >
        <VBtn v-if="!!link.length" :href="link" depressed>Перейти к квалификации</VBtn>
        <VBtn v-if="needGoToSurvey && !!surveyLink.length" :href="surveyLink" color="success" outlined depressed>Пройти опрос</VBtn>
        <VBtn v-if="!!instruction.length" :href="instruction" target="_blank" class="float-right ml-2" depressed color="info lighten-1">Инструкция</VBtn>
        <VTooltip
            v-if="isHelpValid"
            v-model="helpOpen"
            :open-on-hover="false"
            :content-class="$style.tooltipContent"
            color="transparent"
            top>
          <template v-slot:activator="{on}">
            <VBtn @click="on.click" class="float-right ml-2" depressed><VIcon v-text="'mdi-help-circle-outline'"/>Помощь</VBtn>
          </template>
          <VCard :class="$style.helpTooltip">
            <VCardTitle class="pb-0">Контактное лицо:</VCardTitle>
            <VCardText v-if="!!help.name" class="my-0 py-1 d-flex"><VIcon v-text="'mdi-account'" class="mr-2"/>{{ help.name }}</VCardText>
            <VCardText v-if="!!help.email" class="my-0 py-1 d-flex"><VIcon v-text="'mdi-email'" class="mr-2"/>
              <a :href="`mailto:${help.email}`">{{ help.email }}</a></VCardText>
            <VCardText v-if="!!help.phone" class="my-0 py-1 d-flex"><VIcon v-text="'mdi-phone'" class="mr-2"/><span v-html="help.phone"/></VCardText>
          </VCard>
        </VTooltip>

      </div>
    </VCardActions>
  </VCard>
</template>

<script>
import {STATUSES} from '@/store/pkomain/enums';
import {get} from 'lodash-es';

export default {
  name: 'PkoCard',
  props: {
    name: { type: String, },
    description: { type: String, },
    icon: { type: String,},
    link: { type: String, },
    help: { type: Object, },
    instruction: { type: String, },
    statuses: { type: Array,},
    additional: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      open: false,
      helpOpen: false,
    };
  },
  computed: {
    isHelpValid() {
      return !!this.help && !!this.help.name.length ||  !!this.help.email.length ||  !!this.help.phone.length;
    },
    needGoToSurvey() {
      return get(this.additional, 'survey.active', false);
    },
    surveyLink() {
      return get(this.additional, 'survey.link', '');
    },
  },
  methods: {
    registryIconColorControl({code}) {
      let icon = 'mdi-progress-check';
      let color = 'warning';
      if (code === STATUSES.NOT_PASSED) {
        icon = 'mdi-alert-circle-outline';
        color = 'error';
      }
      if (code === STATUSES.PASSED) {
        icon = 'mdi-check-circle-outline';
        color = 'success';
      }
      return {icon, color};
    },
  },
}
</script>

<style module lang="scss">
.root {}
.card {
  min-height: 308px;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  .subtitle {
    min-height: 4.5em;
    font-size: 1.1em;
  }
}
.status {
  font-size: 13px;
  margin: 4px 0;
}
.link {
  cursor: pointer;
}
.helpTooltip {
  :global {
    .v-card__title {
      font-size: 16px;
    }
  }
}
.tooltipContent {
  pointer-events: auto !important;
  opacity: 1 !important;
}
</style>